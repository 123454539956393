#nav {
    height: 60px;
  }
  
  .logo {
    width: 120px;
    height: 55px;
    margin-left: 50px;
    margin-top: 0px;
  }
  
  .logo:hover {
    cursor: pointer;
  }
  .listitem {
    display: inline-block;
    list-style: none;
    margin: 10px 20px;
  
  }
  .link {
    color: black;
    text-decoration: none;


    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
font-weight: 400;

  }
  
  .link:hover {
    text-decoration: underline #E38118 2px;

  }

  .heading1{
    color: #186D44;

    font-weight: bold;

    font-family: 'Montserrat', sans-serif;
font-size: 55px;
font-weight: 600;
line-height: 51.43px;
text-align: left;

  }
.heading2{
    font-weight: 600;
    letter-spacing: 1px;

    font-family: 'Montserrat', sans-serif;
font-size: 23px;
font-weight: 500;
line-height: 45.1px;
text-align: left;

}
.travel-btn{
  background: #0F683E;
color: white;
font-family: 'Work Sans', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 23.46px;
letter-spacing: 0.1em;
text-align: left;
padding: 10px;

}
.travel-btn:hover{
  background: #0F683E;
  color: white;

}
.tavel-btn-div{
  margin-top: 5%;
}
.col-maindiv{
  margin-top: 15%;
}
.circle{
    width: 380px;
    height: 380px;
    border-radius: 50%;
    /* background-color: aliceblue; */
    /* border-color: black; */
    border: 1px solid black; /* Black border */
    display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 14%;


}

.home-container{
    margin-top: 4%;
}



body {
  font-family: Arial, sans-serif;
}

h1 {
  color: #2d6a4f;
}

p {
  color: #6c757d;
}

button.btn {
  background-color: #2d6a4f;
  border-color: #2d6a4f;
}

.circle-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-item {
  position: absolute;
  /* width: 120px;
  height: 120px; */
  width: 193px;
  height: 225px;

  overflow: hidden;
  border-radius: 15px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.image-item img {
  width: 100%;
  height: 100%;
  display: block;
}

.rotate-2 {
  top: 170px;
  left: 50%;
  transform: translateX(-62%) rotate(0deg);
}

/* .rotate-2 {
  top: 50%;
  left: 10px;
  transform: translateY(-50%) rotate(10deg);
} */
.rotate-1 {
  top: 50%;
  left: -27px;
  transform: translateY(-71%) rotate(0deg);
  
}

/* .rotate-3 {
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(-10deg);
} */


.rotate-3 {
  top: 40%;
  right: -58px;
  transform: translateY(-37%) rotate(0deg);
  z-index: -1;
}


.ellipse-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto; /* Center the container horizontally */
}

.ellipse-item {
  position: absolute;
  overflow: hidden;
}

.ellipse-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ellipse-left {
  width: 283px;
  height: 350px;
  left: 0;
  top: 50%;
  transform: translate(-25%, -50%) rotate(0deg);
}

.ellipse-right {
  width: 207px;
  height: 250px;
  right: 72px;
  top: 83%;
  transform: translate(25%, -50%) rotate(0deg);
  border: 2px solid #E38118; 
  padding: 7px;
  border-radius: 50%; /* Make it circular */
  z-index: 1; /* Ensure it overlaps the first image */
}
/* .ellipsis-border{
  width: 202px;
  height: 252px;
  right: 72px;
  top: 83%;
  transform: translate(25%, -50%) rotate(0deg);
  border: 3px solid #000; 
  border-radius: 50%; 
  
} */

.aboutus-container{
  margin-top: 4%;
}
.aboutus-text{
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
font-weight: 600;
line-height: 39.01px;
letter-spacing: 0.02em;
text-align: left;
color: #186D44;
margin-top: 6%;

}
.aboutus-desc{
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 28.5px;
letter-spacing: 0.02em;
text-align: justify;
margin-top: 2%;

}
.vm-row{
  margin-top: 9%;
  margin-bottom: 10%;
}
.vision-content{
  padding: 40px;
  background: #F5F5F5;
  height: 270px;

}

.mission-content{
  padding: 40px;
  background: #186D44;
  height: 270px;

}
.v-heading{
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
font-weight: 400;
line-height: 39.01px;
letter-spacing: 0.11em;
text-align: left;

}
.m-heading{
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
font-weight: 400;
line-height: 39.01px;
letter-spacing: 0.11em;
text-align: left;
color: white;
}
.v-description{
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
/* font-weight: 400; */
line-height: 24.5px;
text-align: justify;
margin-top: 3%;

}
.m-description{
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
/* font-weight: 400; */
line-height: 24.5px;
text-align: justify;
margin-top: 3%;
color: #FFFFFF;

}
.director-msg-container{
  background: #F5F5F5;
  max-width: 100%;

}
.director-ms-content{
  padding: 40px;
}
.dir-msg-heading{
  font-family: 'Montserrat', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 39.01px;
letter-spacing: 0.02em;
text-align: left;
background: #186D44;
color: white;
width: 360px;
padding: 4px;
padding-left: 4%;


}
.dir-msg-desc1{
  margin-top: 3%;
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 26.5px;
letter-spacing: 0.02em;
text-align: justify;

}
.dir-msg-desc2{
  margin-top: 2%;
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 26.5px;
letter-spacing: 0.02em;
text-align: justify;

}
.dir-msg-desc3{
  margin-top: 2%;
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 26.5px;
letter-spacing: 0.02em;
text-align: justify;

}
.director-img img{
  width: 200px;
  height: auto;
}
.director-img{
  margin-top: 14%;
}
.travelen-img img{
  width: 270px;
  height: auto;
}
.travelen1 img{
  height: 500px;
  width: 100%;
}
.travelen2 img{
  height: 500px;
  width: 100%;

}
.trav-3{
  height: 245px;
  width: 100%;

}
.trav-4{
  height: 245px;
  margin-top: 3%;
  width: 100%;

}
.travelen-row{
  margin-top: 2%;
  margin-bottom: 5%;
}
.travelen2{
  height: 250px;
  width: 100%;
top: 3072.5px;
left: 510px;
gap: 0px;
border: 2px 0px 0px 0px;
opacity: 0px;
border: 2px solid #E38118
}
.trav-main-div img{
  height: 500px;
  width: 100%;
}
.large-content{
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
/* font-weight: 600; */
line-height: 26.5px;
text-align: center;

}
.span-travelen{
  font-weight: 600;
}
.service-container{
  background: #F5F5F5;
  max-width: 100%;
  margin-top: 4%;
}
.service1 img{
  width: 210px;
}
.service2 img{
  width: 210px;
}
.service3 img{
  width: 210px;
}
.service4 img{
  width: 210px;
}
.service5 img{
  width: 210px;
}
.service {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100%; /* Make sure the parent div takes the full height of the column */
}
.service-row{
  margin-top: 5%;
  
}
.arrow-line {
  position: relative;
  width: 250px; /* Width of the arrow line */
  height: 2px; /* Thickness of the arrow line */
  background-color: black; /* Color of the arrow line */
  margin: 20px; /* Margin for positioning */
  background-color: #186D44;
}

.arrow-line::after {
  content: '>';
  position: absolute;
  top: 50%;
  right: -10px; /* Adjust this value to position the symbol correctly */
  transform: translateY(-50%);
  font-size: 20px; /* Adjust the size of the symbol as needed */
  color: #186D44; /* Color of the symbol */
}
.space{
  height: 100px;
}
.services-text{
  font-family: 'Montserrat', sans-serif;
font-size: 23px;
font-weight: 600;
line-height: 39.01px;
text-align: left;
color: #186D44; /* Color of the symbol */

}
.service-img-desc{
  font-family: 'Montserrat', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 29.26px;
text-align: center;
margin-top: 3%;

}
.logo-footer img{
  width: 150px;
}
.footer-text1{
  font-family: 'Montserrat', sans-serif;
font-size: 13px;
font-weight: 500;
line-height: 19.92px;
text-align: justify;

}
.footer-content2{
  margin-top: 11%;
}
.footer-con{
  font-family: 'Montserrat', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 25.5px;
text-align: left;
margin-left: 17px;

}
.footer-icons {
  width: 30px; /* or any fixed width you prefer */
  display: flex;
  justify-content: center;
}
.footer-con a {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit the color of the parent element */
  text-decoration: underline;
}
.footer-con a:hover{
  color: blue;
}
.last-footer{
  margin-bottom: 2%;
}
.last-footer-content{
  font-family: 'Montserrat', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 19.5px;
text-align: left;
color: #777777;

}
.foot1{
  padding-left: 7%;
}
.foot2{
  padding-right: 2%;
}
.arrow-line2 {
  position: relative;
  width: 750px; /* Width of the arrow line */
  height: 2px; /* Thickness of the arrow line */
  background-color: black; /* Color of the arrow line */
  margin: 20px; /* Margin for positioning */
  background-color: #186D44;
}

.arrow-line2::after {
  content: '>';
  position: absolute;
  top: 50%;
  right: -10px; /* Adjust this value to position the symbol correctly */
  transform: translateY(-50%);
  font-size: 20px; /* Adjust the size of the symbol as needed */
  color: #186D44; /* Color of the symbol */
}
.review-container{
  margin-top: 5%;
  
}
.email-div{
  width: 80px;
  height: 80px;
  background-color: #186D44;
  border-radius: 50%;
  display: flex;
  justify-content: center;
            align-items: center;
            font-size: 2rem;
            color: #FFFFFF;
            font-family: 'Montserrat', sans-serif;
            

}
.reviewer-name{
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
font-weight: 600;
line-height: 39.01px;
text-align: left;

}
.photo-count{
  font-family: 'Montserrat', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 25.5px;
letter-spacing: 0.03em;
text-align: left;
padding-left: 8px;

}
.review-count{
  font-family: 'Montserrat', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 25.5px;
letter-spacing: 0.03em;
text-align: left;


}




.star-rating {
  display: flex;
  direction: rtl; /* Right-to-left for better visual control */
  font-size: 2rem;
  text-align: left;
}

.star-rating input {
  display: none;
}

.star-rating label {
  cursor: pointer;
  /* color: #ddd; */
  color: #f5b301;

}

.star-rating input:checked ~ label {
  color: #f5b301;
}

.star-rating input:hover ~ label {
  color: #f5b301;
}

.star-rating input:hover ~ label:hover,
.star-rating input:hover ~ label:hover ~ label {
  color: #f5b301;
}
.rating-container {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  /* padding: 10px;  */
}
.month-count{
  font-family: 'Montserrat', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 19.5px;
letter-spacing: 0.03em;
text-align: left;
padding-top: 14px;
padding-left: 3%;

}
.review-desc{
  font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 25.5px;
letter-spacing: 0.03em;
text-align: justify;
margin-top: 2%;

}
.review-content{
  padding-left: 7%;
}
.review-photos{
  margin-top: 4%;
}
.review-photos img{
  width: 150px;
  height: 120px;
  margin-right: 10px;
  
}
.review-photos img:last-child {
  margin-right: 0;
}
.scroll-arrow {
  font-size: 24px;
  user-select: none;
  background-color: white;
  border: none;

  cursor: pointer;
  margin: 0 5px; /* Adjust margin for spacing */
}


.review-row {
  /* transition: transform 0.5s ease-in-out; */
  width: 100%;
  height: 400px;
}
.scroll-arrow-left{
  margin-top: 5%;
}


